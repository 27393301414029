var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"uk-container"},[_vm._m(0),_c('div',{staticClass:"uk-child-width-1-3@m uk-grid-small uk-grid-match",attrs:{"uk-grid":""}},[_vm._m(1),(
        _vm.user.role === _vm.Roles.SalesRep ||
        _vm.user.role === _vm.Roles.Admin ||
        _vm.user.role === _vm.Roles.SalesManager
      )?_c('div',[_vm._m(2)]):_vm._e(),(
        _vm.user.role === _vm.Roles.SalesRep ||
        _vm.user.role === _vm.Roles.Admin ||
        _vm.user.role === _vm.Roles.SalesManager
      )?_c('div',[_vm._m(3)]):_vm._e()]),_c('div',{staticClass:"uk-padding uk-text-center"},[_c('a',{staticClass:"uk-button uk-button-default",on:{"click":_vm.logout}},[_vm._v("Log Out")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"uk-text-center uk-padding"},[_c('h1',[_vm._v("Choose your application...")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"uk-card uk-card-default"},[_c('div',{staticClass:"uk-card-header"},[_c('div',{staticClass:"uk-grid-small uk-flex-middle",attrs:{"uk-grid":""}},[_c('div',{staticClass:"uk-width-expand"},[_c('h3',{staticClass:"uk-card-title uk-margin-remove-bottom"},[_vm._v(" Shade Designer ")]),_c('p',{staticClass:"uk-text-meta uk-margin-remove-top"},[_vm._v(" https://sd.frankford.co ")])])])]),_c('div',{staticClass:"uk-card-body"},[_c('p',[_vm._v(" Design a custom umbrella to your unique specifications using our 3D configurator. ")])]),_c('div',{staticClass:"uk-card-footer"},[_c('a',{staticClass:"uk-button uk-button-text",attrs:{"target":"sd","href":"https://sd.frankford.co"}},[_vm._v("Launch »")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"uk-card uk-card-default"},[_c('div',{staticClass:"uk-card-header"},[_c('div',{staticClass:"uk-grid-small uk-flex-middle",attrs:{"uk-grid":""}},[_c('div',{staticClass:"uk-width-expand"},[_c('h3',{staticClass:"uk-card-title uk-margin-remove-bottom"},[_vm._v(" Product Order Form ")]),_c('p',{staticClass:"uk-text-meta uk-margin-remove-top"},[_vm._v(" https://quotes.frankford.co ")])])])]),_c('div',{staticClass:"uk-card-body"},[_c('p',[_vm._v("Create an open customer quote or submit items to the Frankford Order Entry team for processing.")])]),_c('div',{staticClass:"uk-card-footer"},[_c('a',{staticClass:"uk-button uk-button-text",attrs:{"target":"sd","href":"https://quotes.frankford.co"}},[_vm._v("Launch »")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"uk-card uk-card-default"},[_c('div',{staticClass:"uk-card-header"},[_c('div',{staticClass:"uk-grid-small uk-flex-middle",attrs:{"uk-grid":""}},[_c('div',{staticClass:"uk-width-expand"},[_c('h3',{staticClass:"uk-card-title uk-margin-remove-bottom"},[_vm._v(" Sales Support Map ")]),_c('p',{staticClass:"uk-text-meta uk-margin-remove-top"},[_vm._v(" https://ssm.frankford.co ")])])])]),_c('div',{staticClass:"uk-card-body"},[_c('p',[_vm._v(" Manage your communications with your customers and engage new sales opportunities. ")])]),_c('div',{staticClass:"uk-card-footer"},[_c('a',{staticClass:"uk-button uk-button-text",attrs:{"target":"sd","href":"https://ssm.frankford.co"}},[_vm._v("Launch »")])])])
}]

export { render, staticRenderFns }